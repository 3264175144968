import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomDark as codeStyle } from "react-syntax-highlighter/dist/esm/styles/prism";
import color from "~styles/color";
import { LinkOutlined } from "@ant-design/icons";
import { css } from "@emotion/react";
import { headerId } from "~components/text-helpers";
import { TextHeader } from "~components/header";
import { Menu, Divider, Tooltip } from "antd";
const { SubMenu } = Menu;
import BlogWrapper from "~components/blog-wrapper";
import Footer from "~components/footer";
import { Emoji } from "emoji-mart";
import CLIPBOARD_ICON from "~icons/clipboard.svg";
import MENU_1 from "~icons/menu-1.svg";
import MENU_2 from "~icons/menu-2.svg";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";

export function CodeHighlight(props: {
  line: number;
  colStart: number;
  colEnd: number;
}) {
  if (props.colEnd < props.colStart) {
    throw new Error("colEnd must be after colStart!");
  }

  return (
    <div
      css={css`
        width: ${7.04 * (props.colEnd - props.colStart)}px;
        left: ${7.04 * props.colStart}px;
        height: 21px;
        margin-left: 13.35px;
        background-color: ${color.dark.blue9 + "28"};
        mix-blend-mode: lighten;
        border-bottom: 1px dashed ${color.dark.blue10 + "bb"};
        position: absolute;
        margin-top: 15px;
        top: ${20.3 * props.line}px;

        /* top: ${19.4 * props.line}px; */

        /* top: ${1.45 * props.line}em; */

        /* This @media tag is just for Chrome */
        /* @-moz-document url-prefix() {
          top: ${20.3 *
        props.line}em;
        } */

        /* Safari specific selector */
        /* @media screen and (min-color-index: 0) and(-webkit-min-device-pixel-ratio:0) {
          top: ${20.3 *
        props.line}px;
        } */

        /* &:not(:root:root) {
          top: ${20.3 * props.line}px;
        } */

        /* @media screen and (-webkit-min-device-pixel-ratio: 0) {
          top: ${19.4 *
        props.line}px;
        } */
        /* @media screen and(-webkit-min-device-pixel-ratio:0) {
          top: ${19.4 *
        props.line}px;
        } */

        transition-duration: 0.1s;
        z-index: 999 !important;
      `}
    />
  );
}

function InlineRef(props: {
  line: number;
  colStart: number;
  colEnd: number;
  onHover: CallableFunction;
  onMouseOut: CallableFunction;
  methodKey: string;
  paramName: string;
}) {
  if (props.colEnd < props.colStart) {
    throw new Error("colEnd must be after colStart!");
  }

  return (
    <Link to={"#" + props.methodKey + "-" + headerId(props.paramName)}>
      <div
        css={css`
          width: ${7.04 * (props.colEnd - props.colStart)}px;
          left: ${7.04 * props.colStart}px;
          height: 21px;
          margin-left: 14px;
          background-color: ${color.dark.blue9 + "28"};
          mix-blend-mode: lighten;
          border-bottom: 1px dashed ${color.dark.blue10 + "bb"};
          position: absolute;
          margin-top: 15px;
          top: ${20.8 * props.line}px;
          transition-duration: 0.1s;
          z-index: 999 !important;
          &:hover {
            cursor: pointer;
            background-color: ${color.dark.blue9 + "66"};
          }
        `}
        onMouseEnter={() => props.onHover()}
        onMouseLeave={() => props.onMouseOut()}
      />
    </Link>
  );
}

export function LeaderboardSubmission(props: { icon: string; link: string }) {
  return (
    <a href={props.link} target="_blank">
      <div
        css={css`
          background-color: ${color.light.blue1};
          border: 1px solid ${color.light.blue2};
          border-radius: 5px;
          margin-bottom: 10px;
          transition-duration: 0.3s;
          &:hover {
            cursor: pointer;
            background-color: ${color.light.blue2}cc;
          }
          @media (max-width: 850px) {
            text-align: center;
          }
        `}
      >
        <img
          src={props.icon}
          alt="AI2 Leaderboard"
          css={css`
            width: 50%;
            padding: 20px;
            @media (max-width: 850px) {
              width: 75%;
            }
            @media (max-width: 600px) {
              width: 100%;
            }
            @media (max-width: 400px) {
              padding: 15px 10px;
            }
          `}
        />
      </div>
    </a>
  );
}

export function APIMethod(props: {
  methodKey: string;
  parentAPI: any;
  setHighlightedParam: CallableFunction;
  highlightedParam: string;
  pageQuery?: object;
  location: any;
}) {
  return (
    <div>
      {props.parentAPI[props.methodKey].description}
      <SideBySideCode
        example={props.parentAPI[props.methodKey].example(
          props.parentAPI[props.methodKey].parameters,
          (paramName: string) =>
            props.setHighlightedParam(props.methodKey + "-" + paramName),
          () => props.setHighlightedParam(""),
          props.methodKey,
          props.pageQuery
        )}
        argsTitle={props.parentAPI[props.methodKey].argsTitle}
      >
        {props.parentAPI[props.methodKey].rightSide === undefined ? (
          props.parentAPI[props.methodKey].parameters.map((parameter: any) => (
            <Attribute
              location={props.location}
              hashPrefix={props.methodKey}
              key={parameter.name}
              name={parameter.name}
              type={parameter.type}
              default={parameter.default}
              required={parameter.required}
              description={parameter.description}
              isHovered={
                props.highlightedParam ===
                props.methodKey + "-" + parameter.name
              }
            />
          ))
        ) : (
          <div
            css={css`
              padding-left: 2%;
              padding-right: 2%;
              margin-top: 10px;
            `}
          >
            {props.parentAPI[props.methodKey].rightSide}
          </div>
        )}
      </SideBySideCode>
    </div>
  );
}

/**
 * This is just a helper component whose props get
 * read by <Code />. Do not remove any props.
 */
export function CodeRef(props: {
  code: string;
  onHover: CallableFunction;
  onMouseOut: CallableFunction;
  methodKey: string;
  paramName: string;
}) {
  return <>{props.code}</>;
}

export function Code(props: {
  children?: React.ReactNode | React.ReactNode[];
  language?: string;
  lines?: string[];
  caption?: string | React.ReactNode;
  background?: string;
  captionColor?: string;
  captionBackground?: string;
  showCopy?: boolean;
}) {
  const [showCopy, setShowCopy] = useState(false),
    [didCopy, setDidCopy] = useState(false),
    [showCopyTooltip, setShowCopyTooltip] = useState(false);

  if (props.children === undefined && props.lines === undefined) {
    throw new Error("Must specify either children, lines, or raw!");
  }
  let renderedCode: string;
  let inlineReferences = [];

  if (props.children !== undefined) {
    if (Array.isArray(props.children)) {
      // allows for both react and string components
      let currentLine = 0;
      let currentColumn = 0;
      renderedCode = props.children
        .map((codeChunk: any, i: number) => {
          let codeString: string;
          if (typeof codeChunk === "object" && "props" in codeChunk) {
            codeString = codeChunk.props.code;
            inlineReferences.push(
              <InlineRef
                key={i}
                line={currentLine}
                colStart={currentColumn}
                colEnd={currentColumn + codeString.length}
                onHover={codeChunk.props.onHover}
                onMouseOut={codeChunk.props.onMouseOut}
                methodKey={codeChunk.props.methodKey}
                paramName={codeChunk.props.paramName}
              />
            );
          } else {
            codeString = codeChunk;
          }
          currentLine += (codeString.match(/\n/g) || []).length;
          currentColumn = codeString.length - codeString.lastIndexOf("\n") - 1;
          return codeString;
        })
        .join("");
    } else if (
      typeof props.children === "object" &&
      "props" in props.children
    ) {
      // just a single element with a <CodeRef />
      renderedCode = props.children.props.code;
    } else {
      // it's a string, let it be.
      renderedCode = String(props.children);
    }
  } else {
    renderedCode = props.lines.join("\n");
  }

  return (
    <div
      css={css`
        background: ${props.background ? props.background : color.gray10};
        border-radius: 0.3em;
        overflow-y: hidden;
        margin-top: 10px;
        pre {
          background-color: transparent !important;
          border-radius: 0;
          padding: 0.5em 1em !important;
          code span {
            color: ${color.light.blue1};
          }
        }
      `}
    >
      {props.caption ? (
        <div
          css={css`
            padding-left: 15px;
            padding-right: 15px;
            margin-bottom: -5px;
            padding-bottom: 6px;
            padding-top: 5px;
            font-weight: 600;
            background-color: ${props.captionBackground
              ? props.captionBackground
              : color.gray9};

            font-size: 13px;
            color: ${props.captionColor ? props.captionColor : color.gray6};
            span {
              color: ${props.captionColor ? props.captionColor : color.gray6};
              font-size: 14px;
              margin-top: -3px;
            }
          `}
        >
          {props.caption}
        </div>
      ) : (
        <></>
      )}
      <div
        css={css`
          position: relative;
          overflow-x: auto;
          overflow-y: hidden;
          pre {
            width: max-content;
            pointer-events: none;
          }
        `}
        onMouseEnter={() => setShowCopy(true)}
        onMouseLeave={() => {
          setShowCopy(false);
          setTimeout(() => setDidCopy(false), 300);
        }}
      >
        {props.showCopy === false ? (
          <></>
        ) : (
          <Tooltip
            title={didCopy ? <>Copied</> : <>Copy</>}
            placement="left"
            color={color.gray9 + "aa"}
            visible={showCopyTooltip}
          >
            <div
              onMouseLeave={() => {
                setTimeout(() => setDidCopy(false), 300);
                setShowCopyTooltip(false);
              }}
              onMouseEnter={() => setShowCopyTooltip(true)}
              css={css`
                position: absolute;
                top: 9px;
                right: 10px;
                color: white;
                background-color: ${color.gray9 + "aa"};
                border: 1px solid ${color.gray7 + "bb"};
                border-radius: 3px;
                padding-left: 8px;
                padding-right: 8px;
                padding-top: 0px;
                padding-bottom: 7px;
                opacity: ${showCopy ? "100%" : 0};
                transition-duration: 0.1s;
                &:hover {
                  cursor: pointer;
                  border: 1px solid ${color.gray6};
                  background-color: ${color.gray9};
                }
              `}
              onClick={() => {
                navigator.clipboard.writeText(renderedCode);
                setDidCopy(true);
              }}
            >
              <img
                src={CLIPBOARD_ICON}
                css={css`
                  width: 12px;
                  opacity: 0.8;
                `}
              />
            </div>
          </Tooltip>
        )}
        {inlineReferences}
        <SyntaxHighlighter
          language={props.language === undefined ? "python" : props.language}
          style={codeStyle}
        >
          {renderedCode}
        </SyntaxHighlighter>
      </div>
    </div>
  );
}

export function Response(props: {
  lines?: string[];
  children?: React.ReactNode;
  includeExample?: boolean;
}) {
  if ((props.children === undefined) === (props.lines === undefined)) {
    throw new Error("Can either specify children or lines!");
  }
  return (
    <div
      css={css`
        pre {
          background-color: transparent !important;
          text-shadow: none !important;

          code {
            color: ${color.gray12} !important;
            text-shadow: none !important;
            span {
              color: ${color.gray12} !important;
            }
          }
        }
      `}
    >
      <Code
        showCopy={false}
        language="python-repl"
        background={color.gray2}
        caption={
          props.includeExample === true ? "Example Response" : "Response"
        }
        captionColor={color.gray10}
        captionBackground={color.gray4}
        {...props}
      />
    </div>
  );
}

export function Attribute(props: {
  name: string;
  type: string;
  description: any;
  default?: string | number;
  hashPrefix: string;
  required?: boolean;
  isHovered?: boolean;
  location?: any;
}) {
  let hash = headerId(props.name);
  if (props.hashPrefix !== undefined) {
    hash = props.hashPrefix + "-" + hash;
  }
  const [showLink, setShowLink] = useState(false);

  let setSelected = props.isHovered === true;
  if (
    !setSelected &&
    props.location !== undefined &&
    props.location.hash.substring(1) === hash
  ) {
    setSelected = true;
  }

  return (
    <>
      <div
        css={css`
          background: ${setSelected
            ? color.dark.blue10 + "77"
            : color.dark.purple10 + "00"};
          margin-bottom: 5px;
          transition-duration: 0.3s;
        `}
      >
        <span id={hash} />
        <div
          css={css`
            padding-top: 5px;
          `}
        >
          <Link to={"#" + hash}>
            <div
              css={css`
                display: inline;
              `}
              onMouseEnter={() => setShowLink(true)}
              onMouseOut={() => setShowLink(false)}
            >
              <h4
                css={css`
                  font-weight: 600;
                  pointer-events: none;
                  padding-left: 2%;
                  font-size: 14px;
                  margin-bottom: inherit;
                  display: inline;
                  color: ${color.dark.geekblue6};
                `}
              >
                {props.name}
              </h4>
              :{" "}
              <span
                css={css`
                  font-weight: 600;
                  color: ${color.gray7};
                  pointer-events: none;
                `}
              >
                {props.type}
                {props.default === undefined ? "" : " = " + props.default}
              </span>
              <LinkOutlined
                css={css`
                  opacity: ${showLink ? "1" : "0"};
                  margin-left: 5px;
                  vertical-align: middle;
                  margin-top: -2px;
                  transition-duration: 0.15s;
                  display: inline;
                  font-size: 15px;
                  pointer-events: none;
                `}
              />
            </div>
          </Link>
          {props.required === true ? (
            <div
              css={css`
                float: right;
                color: ${color.light.red6};
                font-weight: 600;
                padding-right: 4%;
              `}
            >
              required
            </div>
          ) : (
            <></>
          )}
        </div>
        <div
          css={css`
            border-bottom: 1px solid ${color.gray5};
            padding-bottom: 1em;
            margin-top: 4px;
            line-height: 17px;
            padding-left: 4%;
            padding-right: 4%;
          `}
        >
          {props.description}
        </div>
      </div>
    </>
  );
}

/**
 * Inline code block.
 */
export function IL(props: { children: React.ReactNode }) {
  return (
    <span
      css={css`
        font-weight: 600;
        font-family: "Inconsolata";
        color: ${color.light.blue6};
        display: inline;
        background-color: ${color.gray3};
        border: 1px dashed ${color.gray6};
        border-radius: 3px;
      `}
    >
      <div
        css={css`
          padding-left: 3px;
          padding-right: 3px;
          display: inline-block;
        `}
      >
        {props.children}
      </div>
    </span>
  );
}

export function VSpace(props: { s?: string }) {
  return (
    <div
      css={css`
        margin-top: ${props.s === undefined ? "60px" : props.s};
      `}
    />
  );
}

export function SideBySideCode(props: {
  example: React.ReactNode | string[];
  children: React.ReactNode;
  argsTitle?: string;
}) {
  return (
    <div>
      <div
        css={css`
          display: inline-block;
          width: 48%;
          margin-top: -7px;
          position: sticky;
          @media (max-width: 750px) {
            display: block;
            width: 100%;
            margin-top: 0px;
            position: static;
          }
          top: 25px;
          vertical-align: top;
        `}
      >
        {Array.isArray(props.example) ? (
          // @ts-ignore: already have checked that it's an array
          <Code lines={props.example} />
        ) : (
          props.example
        )}
      </div>
      <div
        css={css`
          width: 50%;
          position: sticky;
          @media (max-width: 750px) {
            display: block;
            width: 100%;
            position: static;
            margin-top: 15px;
          }
          vertical-align: top;
          margin-left: 2%;
          display: inline-block;
          top: 0px;
        `}
      >
        {props.argsTitle ? (
          <h3
            css={css`
              font-weight: 600;
              padding-bottom: 5px;
              margin-bottom: 0px;
              font-size: 14px;
              border-bottom: 1px solid ${color.dark.geekblue8};
              color: ${color.dark.geekblue6};
              border-radius: 5px 5px 0px 0px;
            `}
          >
            {props.argsTitle}
          </h3>
        ) : (
          []
        )}
        {props.children}
      </div>
    </div>
  );
}

export function Warning(props: {
  children: React.ReactNode;
  marginTop?: string;
  marginBottom?: string;
}) {
  return <Note backgroundColor={color.dark.red5} name="Warning" {...props} />;
}

export function Remark(props: {
  children: React.ReactNode;
  marginTop?: string;
  marginBottom?: string;
  backgroundColor?: string;
}) {
  return (
    <Note
      backgroundColor={
        props.backgroundColor === undefined
          ? color.light.blue6
          : props.backgroundColor
      }
      name="Remark"
      {...props}
    />
  );
}

/**
 * Question and answer text format.
 */
export function Question(props: {
  question: React.ReactNode | string;
  answer: React.ReactNode | string;
}) {
  return (
    <div
      css={css`
        margin-bottom: 20px;
      `}
    >
      <div
        css={css`
          font-weight: 600;
        `}
      >
        {props.question}
      </div>
      <div
        css={css`
          color: ${color.gray8};
        `}
      >
        {props.answer}
      </div>
    </div>
  );
}

function Note(props: {
  children: React.ReactNode;
  marginTop?: string;
  marginBottom?: string;
  backgroundColor: string;
  name: string;
}) {
  return (
    <div
      css={css`
        background-color: ${props.backgroundColor + "30"};
        padding: 15px 15px;
        padding-top: 10px;
        margin-bottom: ${props.marginBottom === undefined
          ? "15px"
          : props.marginBottom};
        margin-top: ${props.marginTop === undefined ? "10px" : props.marginTop};
        border-left: 5px solid ${props.backgroundColor + "80"};
      `}
    >
      <div
        css={css`
          font-weight: 600;
          margin-bottom: 5px;
          font-size: 15px;
        `}
      >
        <span>{props.name}</span>
      </div>
      <div
        css={css`
          line-height: 20px;
          margin-bottom: 0px;
          p:last-child {
            margin-bottom: 0px;
          }
        `}
      >
        {props.children}
      </div>
    </div>
  );
}

function MenuEmoji(props: { emoji: string }) {
  return (
    <span
      css={css`
        .emoji-mart-emoji {
          margin-bottom: -3px;
          display: inline-block;
          vertical-align: middle;
          margin-right: 5px;
        }
      `}
    >
      <Emoji emoji={props.emoji} size={18} />
    </span>
  );
}

function getWindowWidth() {
  if (typeof window === "undefined") {
    // this only happens when statically building
    return 800;
  }
  const { innerWidth: width } = window;
  return width;
}

function MenuDivider(props: { title: string }) {
  return (
    <Divider
      orientation="center"
      style={{
        color: "#8aa5ff",
        borderColor: "#5273e0",
        fontSize: "14px",
        marginBottom: "8px",
      }}
    >
      {props.title}
    </Divider>
  );
}

function MenuPage(props: {
  title: string;
  emoji: string;
  isSelected: boolean;
}) {
  return (
    <div
      css={css`
        padding-left: 15px;
        padding-right: 15px;
        color: ${props.isSelected
          ? color.light.blue2
          : color.light.blue2 + "aa"};
        padding-top: 10px;
        padding-bottom: 10px;
        margin-left: -5px;
        width: calc(100% + 10px);

        background-color: ${props.isSelected
          ? color.dark.geekblue3
          : "transparent"};

        &:hover {
          background-color: ${color.dark.geekblue3};
        }
      `}
    >
      <MenuEmoji emoji={props.emoji} />
      {props.title}
    </div>
  );
}

function DocumentationMenu(props: {
  isCollapsed: boolean;
  setCollapsed: any;
  columnKey: string;
}) {
  if (props.isCollapsed) {
    return (
      <div
        css={css`
          opacity: 0.85;
          padding: 5px;
          padding-top: 0px;
          margin-top: 15px;
          margin-left: 15px;
          border-radius: 3px;
          position: sticky;
          top: 10px;
          z-index: 99 !important;
          float: left;
          &:hover {
            background-color: ${color.gray5};
            opacity: 1;
            cursor: pointer;
          }

          @media (max-width: 1000px) {
            margin-left: 8px;
          }
        `}
        onClick={() => props.setCollapsed((prev: boolean) => !prev)}
      >
        <img
          src={MENU_1}
          css={css`
            height: 13px;
          `}
        />
      </div>
    );
  }
  return (
    <div
      className="noselect"
      css={css`
        width: 256px;
        z-index: 999999 !important;
        position: sticky;
        left: 0px;
        height: 100vh;
        top: 0px;
        overflow-y: scroll;
        display: inline-block;
        vertical-align: top;
        background-color: ${color.dark.blue1} !important;
        color: white;
        padding: 0px 10px;

        scrollbar-width: none;
        &::-webkit-scrollbar {
          width: 0;
          height: 0;
          background: transparent;
        }
      `}
    >
      <div
        css={css`
          padding-left: 5px;
          padding-right: 5px;
          font-weight: 600;
          margin-top: 15px;
          margin-bottom: 20px;
          color: ${color.dark.geekblue10};
          font-size: 15px;
        `}
      >
        iTHOR API Reference
        <div
          className="noselect"
          css={css`
            opacity: 0.85;
            padding: 5px;
            padding-top: 0px;
            margin-top: -1px;
            border-radius: 3px;
            position: relative;
            z-index: 99 !important;
            float: right;
            &:hover {
              background-color: ${color.gray9 + "aa"};
              opacity: 1;
              cursor: pointer;
            }
          `}
          onClick={() => props.setCollapsed((prev: boolean) => !prev)}
        >
          <img
            src={MENU_2}
            css={css`
              height: 13px;
            `}
          />
        </div>
      </div>
      <MenuDivider title="Getting Started" />
      <Link to="/ithor/documentation">
        <MenuPage
          title="Installation"
          emoji="computer"
          isSelected={props.columnKey === "installation"}
        />
      </Link>
      <Link to="/ithor/documentation/concepts">
        <MenuPage
          title="Concepts"
          emoji="closed_book"
          isSelected={props.columnKey === "concepts"}
        />
      </Link>
      <MenuDivider title="Setup" />
      <Link to="/ithor/documentation/initialization">
        <MenuPage
          title="Initialization"
          emoji="star-struck"
          isSelected={props.columnKey === "initialization"}
        />
      </Link>
      <Link to="/ithor/documentation/environment-state">
        <MenuPage
          title="Images & Metadata"
          emoji="earth_americas"
          isSelected={props.columnKey === "environmentState"}
        />
      </Link>
      <Link to="/ithor/documentation/scenes">
        <MenuPage
          title="Scenes"
          emoji="world_map"
          isSelected={props.columnKey === "scenes"}
        />
      </Link>
      <MenuDivider title="Objects" />
      <Link to="/ithor/documentation/objects/object-types">
        <MenuPage
          title="Object Types"
          emoji="chair"
          isSelected={props.columnKey === "objectTypes"}
        />
      </Link>
      <Link to="/ithor/documentation/objects/set-object-states">
        <MenuPage
          title="Set Object States"
          emoji="rose"
          isSelected={props.columnKey === "setObjectStates"}
        />
      </Link>
      <Link to="/ithor/documentation/objects/domain-randomization">
        <MenuPage
          title="Domain Randomization"
          emoji="snowman"
          isSelected={props.columnKey === "domainRandomization"}
        />
      </Link>
      <MenuDivider title="Agent Actions" />
      <Link to="/ithor/documentation/navigation">
        <MenuPage
          title="Navigation"
          emoji="runner"
          isSelected={props.columnKey === "navigation"}
        />
      </Link>
      <Link to="/ithor/documentation/interactive-physics">
        <MenuPage
          title="Object Movement"
          emoji="baseball"
          isSelected={props.columnKey === "moving-objects"}
        />
      </Link>
      <Link to="/ithor/documentation/object-state-changes">
        <MenuPage
          title="Object State Changes"
          emoji="book"
          isSelected={props.columnKey === "object-state-changes"}
        />
      </Link>
    </div>
  );
}

export function APIReferenceiTHOR(props: {
  children: React.ReactNode;
  columnKey: string;
  pageName: string;
  includeTOC?: boolean;
}) {
  const [currentPage, setCurrentPage] = useState(
      props.columnKey === undefined ? "" : props.columnKey
    ),
    [windowWidth, setWindowWidth] = useState(getWindowWidth()),
    [menuIsCollapsed, setMenuCollapsed] = useState(false);

  useEffect(() => {
    const resizeWindow = () => {
      setWindowWidth(getWindowWidth());
    };
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  });

  return (
    <>
      <TextHeader
        navigationMaxWidth="calc(100% - 55px)"
        projectSearch="ithor"
      />
      <div
        css={css`
          display: grid;
          grid-gap: 0px 0px;
          grid-template-columns: ${menuIsCollapsed
            ? "0px 100%"
            : "256px calc(100% - 256px)"};
          @media (max-width: 1000px) {
            grid-template-columns: 0px 100%;
          }
        `}
      >
        <div
          css={css`
            grid-row: 1;
            grid-column: 1;
          `}
        >
          <DocumentationMenu
            isCollapsed={menuIsCollapsed}
            setCollapsed={setMenuCollapsed}
            columnKey={props.columnKey}
          />
        </div>
        <div
          css={css`
            width: 100%;
            margin-left: 0px;
            display: inline-block;
            vertical-align: top;
            grid-column: 2;
            grid-row: 1;
          `}
        >
          {props.includeTOC === false ? (
            <div
              css={css`
                padding-left: 70px;
                padding-right: 70px;
                padding-bottom: 100px;
                padding-top: 50px;
              `}
            >
              {props.children}
            </div>
          ) : (
            <BlogWrapper
              includeFooter={false}
              includeHeader={false}
              maxContentWidth="100%"
              keywords="iTHOR, API, Documentation, Python"
              description={`${props.pageName} documentation for the iTHOR environment within AI2-THOR.`}
            >
              {props.children}
            </BlogWrapper>
          )}
          <Footer preventMaxWidth={true} />
        </div>{" "}
      </div>
    </>
  );
}
